//
// SEARCH RESULTS
//

.search-results:not(body) {
  display: flex;
  margin: 0 -15px;

  .search-results__content {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(65% - 30px);
      max-width: calc(65% - 30px);
    }
  }

  .search-results__sidebar {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(35% - 30px);
      max-width: calc(35% - 30px);
    }
  }
}


//
// SEARCH RESULT SINGULAR
//

.search-result {
  padding: 50px 0;
}

.search-result + .search-result {
  border-top: 5px solid #fff;
}
