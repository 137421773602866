//
// SIDEBAR MODULE
//

.sidebar-module {
  padding: 50px 45px 55px;

  // STATES
  & + .sidebar-module {
    margin-top: 30px;
  }

  // VARIATIONS
  &--white {
    background-color: #fff;
    box-shadow: 0 1px 20px rgba(0, 0, 0, .05);
  }

  &--menu {
    background: #ededed;

    h4 {
      margin-bottom: 15px;
    }
  }
}


//
// SIDEBAR MENU
//

.sidebar-menu {
  padding: 0;
  margin: 0;
  list-style: none;

  &__item {
    position: relative;
    padding: 5px 15px 5px 16px;

    &:before {
      position: absolute;
      top: 50%;
      left: 0;
      font-family: "Font Awesome 5 Pro", sans-serif;
      font-size: .8rem;
      font-weight: 900;
      color: #777;
      content: "\f054";
      transform: translateY(-50%);
    }
  }

  &__link {
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: #000;

    &:hover {
      color: #f69332;
    }
  }
}
