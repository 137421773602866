//
// BUTTONS
//

.btn:not(.btn-primary):not(.btn-secondary):not(.btn-info) {
  font-weight: 500;
  color: $orange;
  background: lighten($orange, 40%);

  &:hover {
    color: $white;
    background: $orange;
  }
}


//
// READ MORE
//

.read-more {
  color: #bdbdbd;
  border-bottom: 1px solid #bdbdbd;

  &:hover {
    color: $black;
    border-bottom-color: $black;
  }
}


//
// MENU BUTTON
//

.btn.menu-btn {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
}

.menu-btn__text {
  order: 2;
}

.menu-btn__icon {
  order: 1;
  margin-right: 8px;

  span {
    display: block;
    height: 2px;
    width: 22px;
    background: $black;
    opacity: 1;
    transition: all .3s ease-in-out;
    transform-origin: center;
  }

  span:nth-child(1) {
    transform: translateY(-3px);
  }

  span:nth-child(3) {
    transform: translateY(3px);
  }

  .menu-btn.toggled & {
    span:nth-child(1) {
      transform: translateY(2px) rotate(-45deg);
    }

    span:nth-child(2) {
      opacity: 0;
    }

    span:nth-child(3) {
      transform: translateY(-2px) rotate(45deg);
    }
  }
}
