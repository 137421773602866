.footer {
  &__inner {
    @extend .container;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
}


//
// Footer Menus
//

.footer__menus {
  padding: 50px 0 80px;
  background: $white;
}

.footer-menu {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 40px;

  @include media-breakpoint-up(md) {
    flex: 1 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
    margin-right: 30px;
  }

  @include media-breakpoint-up(lg) {
    flex: 1 0 38%;
    max-width: 38%;
  }
}

.footer-menu__title {
  flex: 1 0 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.footer-menu__list,
.footer-menu__address {
  flex: 1 0 50%;
  max-width: 50%;
  padding-right: 15px;
}

.footer-menu__address {
  strong {
    display: inline-block;
    width: 25px;
  }
}


//
// Footer Social
//

.footer-social {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.social-link {
  display: block;
  width: 34px;
  height: 34px;
  font-size: 1.35rem;
  color: $black;
  border: 1px solid $black;
  border-radius: 50%;
}


//
// Footer Branding
//

.footer__branding {
  padding: 10px 0;
  background-color: $orange;

  .footer__inner {
    align-items: center;
    justify-content: space-between;
  }
}


.footer__slogan {
  font-size: 1.25rem;
  font-weight: 300;
  color: $white;

  strong {
    font-weight: 700;
  }
}


.footer__logo {
  display: block;
  width: 128px;
  margin-bottom: 10px;
}
