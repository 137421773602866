/* Downloads: Lists */
.downloads.content--highlight {
  border-bottom: 2px solid #f7f7f7;
}

.downloads__title {
  margin-bottom: 50px;
  font-size: 28px;
  font-weight: 500;
}

.downloads__title strong {
  font-weight: 900;
}

.downloads__content {
}



/* Downloads: List */
.download-list {

}

.download-list__title {
  margin-bottom: 25px;
}



/* Downloads: Link */
.download-link {
  display: flex;
  align-items: center;
}

.download-link svg {
  display: block;
  width: 24px;
  height: 32px;
}

.download-link .text {
  margin-left: 12px;
  font-size: 16px;
  color: #000;
}

.download-link .text strong {
  font-weight: 900;
}
