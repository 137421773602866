//
// CONTENT::CONTACT
//

.content--contact {
  .content__inner {
    position: relative;

    &::after {
      position: absolute;
      right: 15px;
      bottom: -80px;
      left: 15px;
      height: 2px;
      content: "";
      background: #dedede;
    }
  }
}


//
// ADDRESS AREAS
//

.contact-details {
  display: flex;
  padding: 0;
  margin: 0;
  flex-flow: row wrap;
  margin: 0 -15px;
}

.contact-details__content {
  flex: 1 0 calc(50% - 30px);
  max-width: calc(50% - 30px);
  margin: 0 15px;
}

.contact-details__title {
  margin-bottom: 1.5rem;
}

.contact-details__heading {
  margin-top: 2.5rem;
  color: $black;
}


//
// CONTENT::CONTACT_FORM
//

.content--contact-form {
  .form-label {
    font-family: "Sofia Pro", sofia-pro, sans-serif;
    font-weight: 700;
    color: $black;
  }

  .form-control {
    background: #ededed;
  }

}


//
// FORMS
//

// Base
.form {
  display: block;
}

// Contact Form
.form--contact {
  .form-group {
    margin-bottom: 1.5rem;
  }

  .btn-lg {
    padding: .75rem 3rem;
    color: $white;
  }
}

// Container
.form__container {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px;
}

// Legend
.form__legend {
  @extend .h3;
  flex: 1 0 calc(25% - 30px);
  max-width: calc(25% - 30px);
  padding-top: 50px;
  margin: 15px;
}

// Fields
.form__fields {
  flex: 1 0 calc(50% - 30px);
  max-width: calc(50% - 30px);
  padding-top: 50px;
  margin: 15px;
}
