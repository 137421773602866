//
// CONTENT COLORS
//

.colors-overview {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px;

  &__content {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(65% - 30px);
      max-width: calc(65% - 30px);
    }
  }

  &__sidebar {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    padding: 70px 0;
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(35% - 30px);
      max-width: calc(35% - 30px);
    }
  }
}


// ADJUSTMENTS FOR OTHER STYLES

.color-palet__item--large {
  flex: 1 0 150px;
  max-width: 150px;
}

.colors-overview .color-palet {
  padding: 0 0 60px;
}

.colors-overview .color-palet + .color-palet {
  border-top: 4px solid #fff;
  padding-top: 60px;
}


//
// COLOR PALET ITEM
//

.color-palet__item--large .color-palet__image {
  width: 130px;
  height: 92px;
}

.color-palet__item small {
  display: block;
  margin-top: 5px;
  font-weight: 900;
  color: #000;
}

.color-palet__item small .badge {
  display: inline-block;
  min-width: 2.1em;
  padding: .3em;
  margin-right: 6px;
  font-size: .6rem;
  font-weight: 500;
  color: #000;
  text-align: center;
  background: #eaeaea;
  border-radius: 50%;
}
