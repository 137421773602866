.product-variants__picker {
  position: relative;
}

.picker-images {
  position: relative;
  z-index: 1;
  margin-bottom: 45px;
  border-radius: 5px;
  box-shadow: 0 3px 12px 0 rgba(99, 99, 99, .15);
}

.picker-images__item {
  display: none;

  img {
    border-radius: 5px;
  }

  &.is-active {
    display: block;
  }

  .picker-images.has-menu & {
    padding-right: 100px;
  }
}

.picker-menu {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  flex-flow: column;
  width: 180px;
  font-family: "Sofia Pro", sans-serif;
  background-image: linear-gradient(#e3e3e3, #f7f7f7);

  header,
  footer {
    background-color: #fff;
  }

  header {
    padding: 20px 25px 15px;
    border-top-right-radius: 5px;
  }

  footer {
    flex: 1 0 auto;
    border-bottom-right-radius: 5px;
  }
}

.picker-menu__title {
  font-size: .85rem;
}

.picker-menu__item {
  padding: 10px 25px;
  font-weight: 700;
  text-align: left;
  background: #fff;
  border: 0;

  &:hover {
    background: rgba(255, 255, 255, .4);
  }

  &:focus {
    outline: none;
  }

  &.is-active {
    background-color: transparent;
  }
}
