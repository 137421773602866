//
// Variables
//

// Holonite vars
$black: #000;
$orange: #f69332;
$silver: #f7f7f7;
$highlight: #f9f9f9;

// Toggle global options
$enable-gradients: false;
$enable-shadows: false;
$enable-rounded: false;

//
// Customize some defaults
//

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1200px,
  xl: 1340px
) !default;


//
// COLORS
//

// Bootstrap Colors
$primary: $orange;
$secondary: $silver;

$body-color: #565656;
$body-bg: #f7f7f7;
$border-radius: .4rem;

$success: #7952b3;


//
// TYPOGRAPHY
//

// Links
$link-hover-decoration: none;

// Body
$font-size-base: .9375rem;
$font-family-base: "Roboto", sans-serif;

// Headings
$headings-font-family: "Sofia Pro", sans-serif;
$headings-color: $orange;
$headings-font-weight: 700;

$h1-font-size: $font-size-base * 1.9 !default;
$h2-font-size: $font-size-base * 1.7 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.3 !default;
$h5-font-size: $font-size-base * 1.1 !default;
$h6-font-size: $font-size-base !default;

// Buttons
$input-btn-font-size: .9375rem;
$input-btn-padding-y: .5rem;
$input-btn-padding-x: 1.375rem;
$input-btn-border-width: 0;
$btn-box-shadow: inset 0 -2px 0 0 rgba($black, .15), 0 3px 12px 0 rgba($black, .15);

