//
// BREADCRUMBS
//

$bc-link-color-normal: #a8a8a8;
$bc-link-color-hover: $black;

.breadcrumbs {
  padding: 0;
  margin: 0;
  list-style: none;
}


// Breadcrumbs item
.breadcrumbs li {
  display: inline-block;

  &:not(:last-child)::after {
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: $bc-link-color-normal;
    content: "\f105";
    padding: 0 .35rem;
  }
}


// Breadcumb link
.breadcrumbs a {
  span {
    color: $bc-link-color-normal;
  }

  // STATE
  &:hover span {
    color: $bc-link-color-hover;
  }
}

// Breadcumb current
.breadcrumbs span {
  color: $bc-link-color-normal;
}
