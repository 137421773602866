//
// Home Slideshow
//

.slideshow {
  position: relative;


  .slide {
    width: 100%;
    height: 50vh;

    img {
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }

    // Media Queries
    @include media-breakpoint-up(md) {
      width: 80%;
      height: 70vh;
      max-height: 850px;
    }
  }


  // Normal slide style
  .slide__content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    padding: 40px;

    &.shaded::after {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      display: block;
      width: 80%;
      padding-bottom: 20%;
      content: "";
      background-image: linear-gradient(195deg, rgba(0, 0, 0, 0) 58%, rgba(0, 0, 0, .65) 100%);
    }
  }


  .slide__title {
    font-family: "Sofia Pro", sofia-pro, sans-serif;
    font-size: 2.375rem;
    font-weight: 900;
    line-height: 1.1;
    color: $white;
  }


  .slide__body {
    font-size: 1.125rem;
    line-height: 1.4;
    color: $white;
  }


  .slide__link {
    display: inline-block;
    color: $white;
    border-bottom: 1px solid $white;
  }


  // Inverted slide style
  .slide__content.inverted {
    .slide__title {
      color: $black;
    }

    .slide__body {
      color: $black;
    }

    .slide__link {
      color: $black;
      border-color: $black;
    }
  }


  // Buttons
  .slideshow__button {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 2.375rem;
    height: 2.375rem;
    background: $orange;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
    transform: translateY(-50%);

    // STATE
    &:hover {
      background: darken($orange, 10%);
    }

    &::before {
      position: absolute;
      top: 50%;
      font-family: "Font Awesome 5 Pro", sans-serif;
      font-size: 1.652rem;
      color: $white;
      transform: translateY(-50%);
    }
  }


  .slideshow__prev {
    left: 10px;
    text-align: right;

    &::before {
      right: 0;
      content: "\f060";
    }

    // Media Queries
    @include media-breakpoint-up(md) {
      left: 20px;
    }
  }

  .slideshow__next {
    right: 10px;
    text-align: left;

    &::before {
      left: 0;
      content: "\f061";
    }

    // Media Queries
    @include media-breakpoint-up(md) {
      right: auto;
      left: calc(80% - 20px);
    }
  }
}


//
// Home Projects Slideshow
//

.slider-wrapper {
  position: relative;
  overflow: hidden;

  &__inner {
    @extend .container;
  }

  // STATE
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: block;
    width: 10%;
    content: "";
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .85) 75%);
  }
}

.projects-reel {
  position: relative;
  overflow: visible !important;

  // Buttons
  &__button {
    position: absolute;
    top: 50%;
    z-index: 10;
    width: 38px;
    height: 38px;
    background: $orange;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
    transform: translateY(-50%);

    // STATE
    &:hover {
      background: darken($orange, 10%);
    }

    &::before {
      position: absolute;
      top: 50%;
      font-family: "Font Awesome 5 Pro", sans-serif;
      font-size: 1.652rem;
      color: $white;
      transform: translateY(-50%);
    }
  }

  &__prev {
    left: 20px;
    text-align: right;

    &::before {
      right: 0;
      content: "\f060";
    }
  }

  &__next {
    right: 20px;
    text-align: left;

    &::before {
      left: 0;
      content: "\f061";
    }
  }
}

.project-slides {
  display: flex;
  align-items: stretch;
}

.project-slide {
  min-height: 300px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 20px 20px;
  background-color: $white;
  box-shadow: 0 3px 12px 0 rgba(99, 99, 99, .15);
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.project-slide__title {
  font-size: 17px;
}

.project-slide__image {
  margin: 0 -20px 25px;

  img {
    width: 100%;
    height: auto;
  }
}

.project-slide__link {
  margin-top: auto;
  text-align: left;
}
