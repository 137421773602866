
.list {
  li {
    padding: 5px 0;
  }
}

.list--arrows {
  padding: 0 0 0 5px;
  list-style: none;

  li::before {
    display: inline-block;
    width: 22px;
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-weight: 900;
    color: $orange;
    content: "\f105";
  }

  a {
    color: $body-color;

    &:hover {
      color: $orange;
    }
  }
}
