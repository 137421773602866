* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
  font-size: 13px;

  // Media Queries
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }

  @include media-breakpoint-up(md) {
    font-size: 15px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

body {
  padding: 0;
  margin: 0;
  background-color: #fff;

  &.show-navigation {
    height: 100vh;
    overflow: hidden;
  }
}


//
// Sticky Footer setup
//

.site-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.site-body > .header,
.site-body > .footer {
  flex: none;
}

.site-body > .main {
  flex: 1 0 auto;
  width: 100%;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}


//
// Base elements
//

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
  -webkit-appearance: button;
}


//
// Gravity Forms
//
#gform_2 #gform_fields_2 #field_2_18,
#gform_2 #gform_fields_2 #field_2_7,
#gform_3 #gform_fields_3 #field_3_17,
#gform_3 #gform_fields_3 #field_3_18,
#gform_4 #gform_fields_4 #field_4_20,
#gform_4 #gform_fields_4 #field_4_21 {
  margin-top: 45px;
}

/* Grid settings */
@include media-breakpoint-up(md) {
  #gform_2 #gform_fields_2 #field_2_18,
  #gform_2 #gform_fields_2 #field_2_7,
  #gform_3 #gform_fields_3 #field_3_17,
  #gform_3 #gform_fields_3 #field_3_18,
  #gform_4 #gform_fields_4 #field_4_20,
  #gform_4 #gform_fields_4 #field_4_21 {
    margin-top: 0;
    margin-bottom: 25px;
  }

  #gform_fields_2,
  #gform_fields_3,
  #gform_fields_4 {
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  #gform_2 #gform_fields_2 #field_2_18,
  #gform_2 #gform_fields_2 #field_2_7,
  #gform_3 #gform_fields_3 #field_3_17,
  #gform_3 #gform_fields_3 #field_3_18,
  #gform_4 #gform_fields_4 #field_4_20,
  #gform_4 #gform_fields_4 #field_4_21 {
    grid-column: 1 / 2 !important;
    margin-bottom: 0;
  }

  #gform_2 #gform_fields_2 [id^="field_2_"],
  #gform_3 #gform_fields_3 [id^="field_3_"],
  #gform_4 #gform_fields_4 [id^="field_4_"] {
    grid-column: 2 / 3 !important;
  }
}

@include media-breakpoint-up(lg) {
  #gform_fields_2,
  #gform_fields_3,
  #gform_fields_4 {
    grid-template-columns: 1fr 2fr 1fr;
  }
}

/* Inputs */
#gform_2 #gform_fields_2 input[type="text"],
#gform_2 #gform_fields_2 input[type="tel"],
#gform_2 #gform_fields_2 input[type="email"],
#gform_2 #gform_fields_2 textarea,
#gform_3 #gform_fields_3 input[type="text"],
#gform_3 #gform_fields_3 input[type="tel"],
#gform_3 #gform_fields_3 input[type="email"],
#gform_3 #gform_fields_3 textarea,
#gform_4 #gform_fields_4 input[type="text"],
#gform_4 #gform_fields_4 input[type="tel"],
#gform_4 #gform_fields_4 input[type="email"],
#gform_4 #gform_fields_4 textarea {
  background: #ededed;
  border: 0;
  padding: 0.5rem 1.375rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

#gform_2 #gform_fields_2 input[type="text"]:focus,
#gform_2 #gform_fields_2 input[type="tel"]:focus,
#gform_2 #gform_fields_2 input[type="email"]:focus,
#gform_2 #gform_fields_2 textarea:focus,
#gform_3 #gform_fields_3 input[type="text"]:focus,
#gform_3 #gform_fields_3 input[type="tel"]:focus,
#gform_3 #gform_fields_3 input[type="email"]:focus,
#gform_3 #gform_fields_3 textarea:focus,
#gform_4 #gform_fields_4 input[type="text"]:focus,
#gform_4 #gform_fields_4 input[type="tel"]:focus,
#gform_4 #gform_fields_4 input[type="email"]:focus,
#gform_4 #gform_fields_4 textarea:focus{
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(246, 147, 50, .25);
}


/* Sections */
#gform_2 .gfield.gsection,
#gform_3 .gfield.gsection,
#gform_4 .gfield.gsection {
  border: 0;

  @include media-breakpoint-up(md) {
    margin-top: 50px;

    & + * {
      margin-top: 50px;
    }
  }
}

/* Radios */
#field_2_2 .gfield_radio,
#field_3_14 .gfield_radio,
#field_4_14 .gfield_radio {
  display: flex;

  .gchoice {
    margin-right: 20px;
  }
}

/* Consent */
#field_2_10 .ginput_container_consent,
#field_3_19 .ginput_container_consent {
  display: flex;

  input {
    margin: 5px 10px 0 0;
  }
}


/* Footer Button */
#gform_wrapper_2 #gform_2 .gform_footer,
#gform_wrapper_3 #gform_3 .gform_footer,
#gform_wrapper_4 #gform_4 .gform_footer {
  margin-top: 50px;

  @include media-breakpoint-up(md) {
    margin-left: calc(33.3333% + 15px);
  }

  @include media-breakpoint-up(lg) {
    margin-left: calc(25% + 15px);
  }
}

#gform_wrapper_2 #gform_2 #gform_submit_button_2,
#gform_wrapper_3 #gform_3 #gform_submit_button_3,
#gform_wrapper_4 #gform_4 #gform_submit_button_4 {
  padding: 0.75rem 3rem;
  color: #fff;
  font-size: 1.171875rem;
  background-color: #f69332;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 0 solid transparent;
  line-height: 1.5;
  border-radius: 0;
  transition: color .15s ease-in-out,
              background-color .15s ease-in-out,
              border-color .15s ease-in-out,
              box-shadow .15s ease-in-out;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #f4800d;
    border-color: #eb790a;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(214, 131, 49, .5);
  }
}
