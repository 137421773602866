.home-news {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin: 0 -15px;
}

.home-news__images {
  margin: 0 -20px 1rem;
}

.home-news__post {
  padding: 0 20px 20px;
  margin: 15px;
  background-color: $white;
  box-shadow: 0 3px 12px 0 rgba(99, 99, 99, .15);

  // STATE
  @include media-breakpoint-up(md) {
    flex: 1 0 calc(33.3333% - 30px);
    max-width: calc(33.3333% - 30px);
  }
}



.news-overview {

}

.news-overview__post {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 0 50px;
}

.news-overview__image {
  max-width: 100%;
  flex: 1 0 100%;
  padding-bottom: 30px;

  @include media-breakpoint-up(md) {
    max-width: 300px;
    flex: 1 0 300px;
    padding-right: 40px;
    padding-bottom: 0;
  }
}

.news-overview__content {
  max-width: 100%;
  flex: 100%;

  @include media-breakpoint-up(md) {
    max-width: calc(100% - 300px);
    flex: 1 0 calc(100% - 300px);
  }
}
