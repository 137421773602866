.home-services {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin: 0 -15px;
}

.home-services__links {
  flex: 1 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
  padding: 0 20px 20px;
  margin: 15px;
  background-color: $white;
  box-shadow: 0 3px 12px 0 rgba(99, 99, 99, .15);

  // STATE
  @include media-breakpoint-up(md) {
    flex: 1 0 calc(33.3333% - 30px);
    max-width: calc(33.3333% - 30px);
  }

  @include media-breakpoint-up(xl) {
    flex: 1 0 calc(20% - 30px);
    max-width: calc(20% - 30px);
  }
}

.home-services__image {
  margin: 0 -20px 30px;
}
