//
// Navigation
//

.navigation {
  position: fixed;
  width: 100%;
  height: 0;
  left: 0;
  top: 0;
  background-color: #fff;
  overflow: hidden;
  z-index: 109;
  transition: height .3s ease-in-out 0s;
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;

  ul {
    list-style: none;
    padding: 0;
  }


  // STATE
  .show-navigation & {
    height: 100vh;
  }

  @include media-breakpoint-up(lg) {
    width: 50vw;
    height: 100vh;
    overflow: visible;
    padding-left: 7.5rem;
    left: -100vw;
    box-shadow: 0 0 2.5rem rgba(50, 73, 80, .3);
    transition: all .3s ease-in-out 0s;

    .show-navigation & {
      left: 0;
    }
  }
}

//
// NAV SEARCH
//


//
// NAV SCROLLABLE
//

.nav-scrollable {
  overflow: auto;
  height: 100%;
  padding-top: 85px;

  //@include media-breakpoint-up(lg) {
  //  padding-top: 85px;
  //}
}


//
// NAV BRAND
//

.nav-brand {
  display: none;
  position: relative;
  padding: 5px 0 30px;
  margin: 0 15px;
  opacity: 0;
  transition: opacity .3s ease-in-out .3s;
  text-align: center;

  a {
    display: inline-block;
    height: 45px;
    width: 162px;
  }

  .show-navigation & {
    left: 0;
    opacity: 1;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    text-align: left;

    a {
      height: 70px;
      width: 250px;
    }
  }
}


//
// NAV LANGUAGE
//

.nav-language {
  padding: 0 5px 30px;

  @include media-breakpoint-up(lg) {
    padding: 0;
    position: absolute;
    top: 35px;
    right: 30px;
    z-index: 40;
  }
}

.lang {
  display: inline-block;
  margin: 0 10px;
  font-size: 1.25rem;
  font-weight: 700;
  color: lighten($orange, 30%);
  text-transform: uppercase;

  &--active {
    color: $orange;
  }
}


//
// NAV MENU MAIN
//

.nav-menu {
  // DEPTH 1 LINK
  .depth-1 > li > a {
    display: block;
    color: $black;
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 8px 15px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
  }

  // DEPTH 1 HOVER
  .depth-1 li.hovered > a {
    //background-color: #efedeb;
    background: $white;
  }

  // DEPTH 2 MENU
  .depth-1 li .depth-2 {
    position: fixed;
    display: none;
    top: 0;
    padding-top: 140px;
    width: 100%;
    height: 100%;
    //background: #efedeb;
    background: $white;
    z-index: 20;

    @include media-breakpoint-up(lg) {
      position: fixed;
      display: flex;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease-in-out 0s;
      //width: 50%;
      //top: 0;
      //right: 0;
      left: 400px;
      min-width: 560px;
      width: calc(50vw - 400px);
      padding-top: 0;
      box-shadow: 2px 0 5px rgba(0, 0, 0, .15);
    }
  }

  // DEPTHE 2 MENU HOVER STATE
  .depth-1 li.hovered .depth-2 {
    display: flex;

    @include media-breakpoint-up(lg) {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.depth-2 {
  li {
    padding: 10px 15px;
  }

  a {
    display: block;
    color: $black;
    padding: 0 15px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;

    &:hover {
      color: $orange;
    }
  }


  ul {
    @include media-breakpoint-up(lg) {
      padding: 190px 20px 50px;
    }
  }
}

.depth-2__back {
  position: absolute;
  top: 76px;
  left: .625rem;
  display: none;
  //background: transparent url(/_Resources/Static/Packages/Bgl.Website/Assets/Images/icon-arrow.svg) 50% no-repeat;
  border: none;
  width: 140px;
  height: 48px;
  z-index: 30;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 10px;
    left: 15px;
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-size: 1.652rem;
    color: $black;
    content: "\f060";
  }

  .nav-menu .depth-1 li.hovered & {
    display: block;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}


//
// NAV MENU SUB
//

.nav-menu--sub {
  li {
    padding: 5px 15px;
  }

  a {
    font-family: "Sofia Pro", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    display: block;
    color: $black;

    &:hover {
      color: $orange;
    }
  }
}


//
// NAV SOCIAL
//

.nav-social {
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    margin: 30px 15px;
    justify-content: flex-start;
  }
}



