.header {
  position: fixed;
  padding: 18px 0;
  z-index: 110;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(#fff 70%, rgba(255,255,255,0.8));

  @include media-breakpoint-up(md) {
    padding: 28px 0;
    z-index: 108;
  }

  // Children
  .container,
  .container-md {
    display: flex;
    align-items: center;
  }


  // Styles
  &:not(.header--page) {
    background: $white;
    border-bottom: 3px solid $orange;

    @include media-breakpoint-up(md) {
      .show-navigation & {
        border: 0;
      }
    }
  }

  //&--page {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //}
}


// Header Logo
.header__logo {
  display: block;
  width: 122px;
  height: 34px;

  @include media-breakpoint-up(md) {
    width: 162px;
    height: 44px;
  }
}


// Search bar
.header__search {
  position: relative;
  padding-right: 15px;
  margin-left: auto;
  display: none;

  .form-control {
    width: 250px;
    height: 40px;
    background: $white;
    border-radius: 6px;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, .15);

    &:focus {
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .25);
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.header__search-button {
  position: absolute;
  top: 5px;
  right: 20px;
  width: 30px;
  font-size: 16px;
  line-height: 28px;
  background: none;
  border: 0;
  border-radius: 50%;
  transition: all 250ms ease-in-out;

  &:hover {
    background: #e4e4e4;
  }

  &:focus {
    outline: none;
  }
}


// Contact button
.header__contact.btn {
  display: none;
  height: 40px;
  margin-right: 15px;
  font-size: 1rem;
  color: $white;
  background: $orange;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, .15), inset 0 -2px 0 0 rgba(0, 0, 0, .15);

  @include media-breakpoint-up(md) {
    display: block;
  }
}


// Contact button
.header__button.btn {
  margin-left: auto;
  display: block;
  height: 40px;
  font-weight: 700;
  text-transform: uppercase;
  background-image: linear-gradient(180deg, #fff 0%, #f2f2f2 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, .15), inset 0 -2px 0 0 rgba(0, 0, 0, .15);

  @include media-breakpoint-up(md) {
    margin-left: 0;
  }
}
