.content {
  padding: 65px 0 85px;


  // Styles
  &--highlight {
    background-color: $white;
  }
}

.content__inner {
  @extend .container;
}

.content__title {
  text-align: center;
  color: $black;
}

.content__title-suffix {
  font-size: 1rem;
  color: $black;
}


//
// Default page styles
//

.page:not(body) {
  display: flex;
  margin: 0 -15px;
  flex-flow: row wrap;

  .page-top {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: -50px 15px 50px;
    height: 20vw;
    max-height: 350px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .page__content {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(65% - 30px);
      max-width: calc(65% - 30px);
    }
  }


  .page__content.page__content--no-sidebar {
    flex: 1 0 calc(100% - 30px) !important;
    max-width: calc(100% - 30px) !important;
  }

  .page__content {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(65% - 30px);
      max-width: calc(65% - 30px);
    }
  }

  .page__sidebar {
    flex: 1 0 calc(100% - 30px);
    max-width: calc(100% - 30px);
    margin: 0 15px;

    @include media-breakpoint-up(md) {
      flex: 1 0 calc(35% - 30px);
      max-width: calc(35% - 30px);
    }
  }
}

//s
// Projects
//
.projects-overview__project {
  padding: 45px 0;
  display: flex;
  align-items: center;

  &:first-child {
    padding-top: 0;
  }

  & + .projects-overview__project {
    border-top: 5px solid #f6f6f6;
  }
}


.project-overview__image {
  flex: 1 0 calc(40% - 15px);
  max-width: calc(40% - 15px);
  margin-right: 15px;
  margin-bottom: 0;

  @include media-breakpoint-up(md) {
    flex: 1 0 calc(40% - 15px);
    max-width: calc(40% - 15px);
  }
}

.project-overview__content {
  flex: 1 0 calc(60% - 15px);
  max-width: calc(60% - 15px);
  margin-left: 15px;
}

//
// Jobs
//

.job-item {
  h6 {
    color: #000;
  }

  & + .job-item {
    margin-top: 30px;
  }
}


//
// Contact
//

.contact-persons {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -15px 25px;
}

.contact-profile {
  flex: 1 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
  margin: 0 15px 30px;
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up('sm') {
    flex: 1 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }

  @include media-breakpoint-up('md') {
    flex: 1 0 calc(33.3333% - 30px);
    max-width: calc(33.3333% - 30px);
  }

  @include media-breakpoint-up('lg') {
    flex: 1 0 calc(25% - 30px);
    max-width: calc(25% - 30px);
  }
}

.contact-profile__body {
  //background: #fff;
  //box-shadow: 0 1px 8px 0 rgba(99, 99, 99, .15);
  background: #f9f9f9;
  padding: 20px 20px 10px;
  flex: 1 0 auto;
}

.contact-profile__image {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px;

  img {
    max-width: 140px;
    border-radius: 50%;
  }
}

.contact-profile__content {
  text-align: center;
}

.contact-top-title {
  color: #000;
  margin: 20px 0 15px;
}

