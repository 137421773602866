.social-link {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  border-color: #e4e4e4;

  &:hover {
    color: #fff;
    background: #f69332;
    border-color: #f69332;
  }
}


.social-link + .social-link {
  margin-left: 8px;
}
