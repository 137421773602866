//
// HOME PRODUCTS
//

.home-products {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin: 0 -15px;
}


.home-products__links {
  flex: 1 0 calc(100% - 30px);
  max-width: calc(100% - 30px);
  padding: 0 20px 20px;
  margin: 15px;
  background-color: $white;
  box-shadow: 0 3px 12px 0 rgba(99, 99, 99, .15);

  // STATE
  @include media-breakpoint-up(md) {
    flex: 1 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
    display: flex;
    flex-direction: column;

    > footer {
      margin-top: auto;
    }
  }

  @include media-breakpoint-up(lg) {
    flex: 1 0 calc(35% - 30px);
    max-width: calc(35% - 30px);
  }
}


.home-products__side {
  padding: 40px 30px 50px;
  margin: 15px;
  background-color: $silver;
  flex: 1 0 calc(100% - 30px);
  max-width: calc(100% - 30px);

  .text-dark {
    font-weight: 400;
  }

  // STATE
  @include media-breakpoint-up(lg) {
    flex: 1 0 calc(30% - 30px);
    max-width: calc(30% - 30px);
  }
}


//
// PRODUCTS OVERVIEW
//

.products {
  padding: 25px 0;

  &__inner {
    @extend .container;
  }
}


.product-list {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding: 80px 0;
  margin: 0 -30px;

  // STATE
  &::after {
    position: absolute;
    right: 30px;
    bottom: -1px;
    left: 30px;
    display: block;
    height: 2px;
    content: "";
    background-color: #dedede;
  }

  &:last-child::after {
    content: none;
  }

  // CHILDREN
  &__title {
    flex: 1 0 calc(100% - 60px);
    max-width: calc(100% - 60px);
    margin: 0 30px;
  }

  &__item {
    flex: 1 0 calc(25% - 60px);
    max-width: calc(25% - 60px);
    padding: 25px 0;
    margin: 0 30px;
  }
}


//
// PRODUCT OVERVIEW
//

.products__collection {
  padding: 60px 0 10px;
}

.products__collection + .products__collection {
  border-top: 4px solid #ededed;
}

.products__collection-title {
  margin-bottom: 40px;
  font-weight: 500;
}

.products__collection-content {
  max-width: 800px;
  padding-bottom: 60px;
  margin-bottom: 60px;
  border-bottom: 4px solid #ededed;
}


.products__list {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  padding: 0;
  margin: 0;
  list-style: none;
}

.products__item {
  flex: 1 0 33.33333%;
  max-width: 33.33333%;
}

.product-overview {
  display: block;
  margin-right: 25%;
  margin-bottom: 60px;

  // CHILDREN
  &__title {
    display: block;
    margin-bottom: 10px;
    font-family: "Sofia Pro", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    color: #000;
  }

  &__link {
    display: inline-block;
    padding: 0;
    font-family: "Sofia Pro", sans-serif;
    font-weight: 500;
    color: #bdbdbd;
    border-bottom: 2px solid currentColor;
    transition: all 200ms ease-in-out;

    // STATES
    .product-overview:hover & {
      color: #f69332;
    }
  }
}

.product-display {

  // CHILDREN
  &__image {
    border: 2px solid #f7f7f7;
    border-radius: 8px;
    transition: all 200ms ease-in-out;

    // STATES
    .product-overview:hover & {
      border-color: #ededed;
    }
  }
}


//
// PRODUCT PREVIEW
//

.product-preview {

  &__title {
    display: block;
    margin-bottom: 14px;
    font-size: 1rem;
    font-weight: 700;
    color: $black;
  }
}


//
// PRODUCT COLOR PALET
//

.color-palet {
  padding: 20px 0;

  &__list {
    display: flex;
    flex-flow: row wrap;
    padding: 0;
    margin: 0 -10px;
    list-style: none;
  }

  &__item {
    flex: 1 0 100px;
    max-width: 100px;
    margin: 10px;
  }

  &__image {
    width: 80px;
    height: 80px;
    background-position: 310px 250px;
    background-size: 462px;
    border-radius: 50%;
  }
}


//
// PRODUCT CAD LINK
//

.pos-r {
  position: relative;
  padding: 20px 0;
}

.goto-cad-link {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  font-family: "Sofia Pro", sans-serif;
  color: #555;
}

.goto-cad-link:hover {
  color: #f69332;
}

.goto-cad-link svg {
  display: block;
  width: 36px;
  height: 32px;
  margin-right: 10px;
}

.goto-cad-link:after {
  margin-left: 10px;
  font-family: "Font Awesome 5 Pro", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #f69332;
  content: "\f054";
}

.text-dark {
  color: #000;
}

//
// PRODUCT VARIANT TABLE
//

.variant-table table {
  width: 100%;
  font-family: "Sofia Pro", sans-serif;
}

.variant-table table tr:first-child td {
  padding-bottom: 18px;
  position: relative;
}

.variant-table table tr:first-child td:before {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 0;
  width: 100%;
  background: #ededed;
  height: 1px;
}

.variant-table table td {
  padding: 2px 0 5px 2px;
}
